export default function PP_PromoHerbExtension() {
    return (
        <div>
            <h1>Privacy Policy for PromoHerb</h1>
            {/* <p>Last updated: [Date]</p> */}

            <h2>Introduction</h2>
            <p>
                Domaining Central ("us", "we", or "our") operates the PromoHerb Chrome extension (the "Service"). This page
                informs you of our policies regarding the collection, use, and
                disclosure of Personal Information when you use our Service.
            </p>

            <h2>Information Collection and Use</h2>
            <p>
                We do not collect personally identifiable information from
                users. However, we may collect certain data through cookies and
                browser storage to enhance your user experience.
            </p>

            <h3>Data Collected</h3>
            <ul>
                <li>
                    <strong>Cookies:</strong> We may use cookies to manage user
                    sessions and preferences.
                </li>
                <li>
                    <strong>Browser Storage:</strong> We may store information
                    related to promo codes and user preferences locally on your
                    browser.
                </li>
                <li>
                    <strong>Active Tab Information:</strong> We only access
                    details about the active tab while our extension is in use
                    to appropriately apply promo codes.
                </li>
            </ul>

            <h2>Use of Data</h2>
            <p>The information we collect is used in the following ways:</p>
            <ul>
                <li>
                    To provide you with the highest discount promo codes
                    automatically when you are on the cart page.
                </li>
                <li>
                    To enhance user experience by remembering your preferences
                    and settings.
                </li>
                <li>
                    To improve our Service based on user feedback and
                    interaction.
                </li>
            </ul>

            <h2>Sharing of Data</h2>
            <p>
                We do not share your personal data with third parties except as
                required by law or to fulfill your requests. We may integrate
                third-party services for functionality, which are governed by
                their own privacy policies.
            </p>

            <h2>Security of Data</h2>
            <p>
                The security of your data is essential to us, but remember that
                no method of transmission over the internet or method of
                electronic storage is 100% secure. While we strive to use
                commercially acceptable means to protect your personal data, we
                cannot guarantee its absolute security.
            </p>

            <h2>Links to Other Sites</h2>
            <p>
                Our Service may contain links to other sites that are not
                operated by us. If you click on a third-party link, you will be
                directed to that third party's site. We strongly advise you to
                review the Privacy Policy and terms of service of every site you
                visit.
            </p>

            <h2>Children's Privacy</h2>
            <p>
                Our Service is not intended for use by children under the age of
                13, and we do not knowingly collect personally identifiable
                information from anyone under that age. If you are a parent or
                guardian and you are aware that your child has provided us with
                Personal Information, please contact us.
            </p>

            <h2>Changes to This Privacy Policy</h2>
            <p>
                We may update our Privacy Policy from time to time. We will
                notify you of any changes by posting the new Privacy Policy on
                this page. You are advised to review this Privacy Policy
                periodically for any changes. Changes to this Privacy Policy are
                effective when they are posted on this page.
            </p>
        </div>
    );
}
